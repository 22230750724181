<script setup lang="ts">
import { Analytics } from '~/js/interfaces/analytics'
import { Blog } from '~/js/interface/blog'

import { getGlobalTranslation } from '~/js/composables/translations'

import TextH3 from '~/vue/_atoms/texts/TextH3.vue'
import TextBody from '~/vue/_atoms/texts/TextBody.vue'
import LinkOverlay from '~/vue/_atoms/links/LinkOverlay.vue'
import ImageTeaser from '~/vue/_atoms/images/ImageTeaser.vue'

interface Props {
    card: Blog,
    utilities?: string,
    ga?: Analytics | null
}

withDefaults(defineProps<Props>(), {
    utilities: ''
})

const types = (type) => {
    const lut = {
        'articlePage': getGlobalTranslation('Artikel'),
        'podcastPage': getGlobalTranslation('Podcast'),
        'videoPage': getGlobalTranslation('Video'),
    }

    return lut[type] ?? lut['articlePage']
}
</script>


<template>
    <article 
        :class="[
            'flex flex-col relative overflow-hidden',
            'rounded-md rounded-br-3xl shadow-md',
            'transition-all',
            'group',
            utilities
        ]"
    >
        <!-- <figure class="aspect-video overflow-hidden w-full">
            <picture>
                <img class="w-full group-hover:scale-[150%] transition-all object-cover h-full" alt="" src="https://fastly.picsum.photos/id/1035/1300/900.jpg?hmac=eyjOpWgGs954dnIJpC3lovrwWQPSOr-4V5xxUHQBbyc" />
            </picture>
        </figure> -->

        <ImageTeaser
            :image="card.teaser"
            utilities="group-hover:scale-[150%] transition-all duration-500"
            ratio="aspect-auto"
            :sizes="['25vw']"
        />

        <div class="min-w-3/4 bg-blue-100 py-2 px-6 -mt-6 rounded-tr-2xl relative z-10">
            <h2 class="block w-full text-ellipsis overflow-hidden">
                <TextH3>
                    {{ card.title }}
                </TextH3>
            </h2>
        </div>

        <div class="space-y-4 z-10 py-4 group-hover:bg-blue-100 relative transition-all">

            <div class="px-6 space-y-4">
                <TextBody utilities="block w-full">
                    {{ card.description }}
                </TextBody>

                <div class="flex space-x-2 text-xs font-primary">
                    <span class="text-purple-600 text-xs font-bold">{{ card.post_date }}</span>
                    <span class="uppercase text-purple-500 font-bold">{{ types(card.type) }}</span>
                </div>
            </div>
        </div>

        <LinkOverlay
            :title="card.title"
            :url="card.url"
            :ga="ga ?? {
                category: 'card-blog',
                action: 'click',
                label: card.title
            }"
        />
    </article>
</template>