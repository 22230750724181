<script setup lang="ts">
import { ref, computed } from 'vue'

import { Facet, FacetCount } from '~/js/interfaces/facets'

import { getGlobalTranslation } from '~/js/composables/translations'

import ButtonPrimary from '~/vue/_atoms/buttons/ButtonPrimary.vue'
import ButtonFilter from '../../_atoms/buttons/ButtonFilter.vue'

interface Props {
    label: string,
    facet: Facet,
    type: string,
    utilities?: string,
    selected?: Array<string> | null,
    dropdownPosition?: string,
    disabled?: boolean
    showCount?: boolean,
    loading: boolean
}

const props = withDefaults(defineProps<Props>(), { 
    utilities: '', 
    selected: null, 
    dropdownPosition: 'left',
    showCount: false,
    disabled: false,
})
const expanded = ref(false)
const emit = defineEmits(['setFacet'])
const uniqueKey = ref(Date.now())

const setFacet = (facet: string) => {
    emit('setFacet', {
        field: props.type, 
        value: facet
    })
}

const onClose = () => {
    expanded.value = false
}

const onToggle = () => {
    expanded.value = !expanded.value
}

const ordered = computed(() => {
    return props.facet.counts
    // return [...props.facet.counts].sort((a: FacetCount, b: FacetCount) => (a.value < b.value) ? -1 : ((b.value < a.value) ? 1 : 0))
})

const uniqueId = computed(() => {
    return `${props.label.toLowerCase().replace(' ','-')}-${uniqueKey.value}`
})
</script>
<template>
    <fieldset
        v-click-away="onClose"
        v-on:keyup.esc="onClose"
        :class="[
            'relative group',
            utilities
        ]"
    >
        <legend class="sr-only">
            {{ label }}
        </legend>
        <ButtonFilter 
            :label="label"
            :aria-controls="uniqueId"
            :aria-expanded="expanded"
            :aria-label="`${expanded ? getGlobalTranslation('Inklappen van') : getGlobalTranslation('Uitklappen van')} ${label.toLowerCase()} ${getGlobalTranslation('Filter').toLowerCase()}`"
            :selected="selected && selected.length > 0 ? selected.length : null"
            :disabled="disabled"
            :loading="loading"
            @click="() => disabled ? null : onToggle()"
        />

        <div 
            v-if="expanded" 
            class="block bg-black/40 w-screen h-screen fixed left-0 bottom-0 z-50 md:hidden"
            @click="onClose" 
        />

        <div
            :class="[
                'pr-1 bg-white md:bg-white/70 border border-gray-200 backdrop-blur-md w-fit min-w-48 rounded-t-3xl md:rounded-lg py-2 shadow-xl md:max-h-[75vh] md:overflow-y-auto md:max-w-s z-50 scrollbar-hide',
                `md:absolute md:top-full md:${dropdownPosition}-0`,
                `fixed left-0 bottom-0 md:bottom-auto md:${dropdownPosition === 'left' ? 'right' : 'left'}-auto`,
                loading ? 'pointer-events-none' : '',
                expanded ? 'block' : 'hidden',
            ]"
            :id="uniqueId"
        >
            <header class="md:hidden py-2 px-6 flex justify-between">
                <span class="text-black font-bold font-primary">{{ label }}</span>
                <button
                    :aria-label="getGlobalTranslation('Sluit')"
                    @click="onClose"
                >
                    <span class="sr-only">{{ getGlobalTranslation('Sluit') }}</span>
                    <i class="fa-regular fa-times" />
                </button>
            </header>

            <template v-for="option in ordered">
                <button
                    :key="option.highlight"
                    v-if="option.count > 0"
                    type="button"
                    :class="[
                        'inline-flex items-center w-full mx-1',
                        'text-black',
                        'py-2 px-4',
                        'text-sm md:text-base text-left font-medium font-primary',
                        'focus:ring-purple-600 ring-inset focus:ring-2 focus:outline-none',
                        'hover:ring-purple-600 hover:ring-2',
                        loading || (option.disabled ?? false) ? 'opacity-40 pointer-events-none' : '',
                    ]"
                    :data-tag-category="`filter-${type}`"
                    data-tag-action="filter"
                    :data-tag-label="option.highlighted"
                    @click="setFacet(option.highlighted)"
                >
                    <span :class="[ selected && selected.find(item => item == option.highlighted) > 0 ? 'inline-flex' : 'hidden' , 'text-base mr-3 text-purple-600' ]">
                        <i class="fa-solid fa-square-check" />
                    </span>

                    <span :class="[ selected && selected.find(item => item == option.highlighted) > 0 ? 'hidden' : 'inline-flex' , 'text-base mr-3 text-gray-400' ]">
                        <i class="fa-regular fa-square" />
                    </span>
                    <span class="md:whitespace-nowrap space-x-1">
                        <span class="pt-1 inline-block">{{ option.value }}</span>
                        <span v-if="showCount">({{ option.count }})</span>
                    </span>
                </button>
            </template>
        </div>
    </fieldset>
</template>