<script setup lang="ts">
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'

import { useJobsStore } from '~/js/stores/jobs'

import { Facet } from '~/js/interfaces/facets'
import { Category } from '~/js/interfaces/job'
import { Location } from '~/js/interface/location'

import { getClasses } from '~/js/composables/classes'
import { getGlobalTranslation } from '~/js/composables/translations'

import ButtonPrimary from '~/vue/_atoms/buttons/ButtonPrimary.vue'
import ButtonSecondary from '~/vue/_atoms/buttons/ButtonSecondary.vue'
import FilterLocation from '~/vue/_molecules/filters/FilterLocation.vue'
import FilterMultiSelect from '~/vue/_molecules/filters/FilterMultiSelect.vue'
import FilterMultiSelectMobile from '~/vue/_molecules/filters/FilterMultiSelectMobile.vue'
import FilterSearch from '~/vue/_molecules/filters/FilterSearch.vue'
import TextH3 from '~/vue/_atoms/texts/TextH3.vue'
import TextBody from '../../_atoms/texts/TextBody.vue'

interface Props {
    sectors: Array<Category> | null
    showSector?: Boolean
    contractTypes: Array<Category>
    workRegimes: Array<Category>
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    sectors: null,
    showSector: true,
    utilities: '',
})

const classes = ref({
    _default: {
        _default: 'relative rounded-br-3xl shadow-md z-20',
        flex: " flex flex-col",
        color: 'bg-beige-600',
        space: 'gap-6 -mt-10',
    },
    badge: {
        _default: 'absolute left-4 sm:left-10 -top-4'
    },
    wrapper: {
        _default: 'w-full',
        space: 'p-4 md:p-6 space-y-8',
    }
})

const jobsStore = useJobsStore()
const { posts, popular, facets, filters, loading } = storeToRefs(jobsStore)
const mobileExpanded = ref(false)
const mobileExpandId = ref(Date.now())

const createFilter = (filterName: string) => {
    const fieldName = filters.value[filterName]?.field ?? null
    const filterFacets = facets.value.find((facet: Facet) => facet.field_name === fieldName)?.counts ?? []

    return {
        counts: props[filterName].map((facet: any) => {
            const filter = filterFacets.find(filter => filter.value == facet.id) ?? null

            return {
                count: filter?.count ?? 0,
                highlighted: facet.id,
                value: facet.title,
            }
        })
    }
}

const clearFilters = () =>  {
    jobsStore.resetFilters(props.showSector)
}

const updateSearch = (search:string, query:string) => {
    jobsStore.setSearch(search, query)
}

const updateLocation = (value: Location|string|number, query:string) => {
    if (typeof value == 'number') {
        jobsStore.setDistance(value)
    } else {
        jobsStore.setLocation(value)
    }
}

const updateFacet = (facet:any) => {
    jobsStore.setFilter(facet.field, facet.value)
}

const toggleMobile = () => {
    mobileExpanded.value = !mobileExpanded.value
}

const scrollToResults = () => {
    const el = document.getElementById('vue-job-results')
    el?.scrollIntoView({behavior: 'smooth'})
}

const filtersSelected = computed(() => {
    return filters.value.contractTypes.values.length + 
        filters.value.workRegimes.values.length + 
        (props.showSector ? filters.value.sectors.values.length : 0)
})

const autocompleteJobs = computed(() => {
    if (posts.value) {
        return [...new Set(posts.value.filter(post => post.document.title).map(post => post.document.title))].sort((a, b) => a.length - b.length)
    }

    return []
})

const popularSuggestions = computed(() => {
    let popularSuggestions = popular.value

    if (popularSuggestions) {
        return popularSuggestions
            .filter(query => query.document.q && query.document.q !== '*' && query.document.q.trim() !== '')
            .sort((a, b) => b.document.count - a.document.count)
            .slice(0, 3)
    }

    return [];
})
</script>

<template>
    <div :class="getClasses(classes, '_default')">
        <!-- <form
            :class="getClasses(classes, 'wrapper')"
            @submit.prevent
        >      -->
        <div 
            role="search"
            :class="getClasses(classes, 'wrapper')"
        >
            <div class="w-full sm:flex gap-8 space-y-4 sm:space-y-0 items-end">
                <!-- :pre-search="useSetQueryParams().find(query => query.type == 'functie')?.values.map(value => value.name)[0] ?? ''" -->
                <FilterSearch 
                    :label="getGlobalTranslation('Functie')"
                    :loading="loading"
                    :placeholder="getGlobalTranslation('Functie, trefwoord, ...')"
                    :pre-search="filters.searchQuery"
                    :show-autocomplete="true"
                    :show-label="true"
                    @setQuery="updateSearch($event, 'searchQuery')"
                    :autocomplete-results="autocompleteJobs"
                    :popular-queries="popularSuggestions"
                    type="functionQuery"
                    utilities="!max-w-full"
                />
                <!-- :pre-search="useSetQueryParams().find(query => query.type == 'locatie')?.values.map(value => value.name)[0] ?? ''"
                @setQuery="($event) => updateFacet({field: 'location', value: $event != '' ? $event : null})" -->
                <FilterLocation 
                    :label="getGlobalTranslation('Locatie')"
                    :placeholder="getGlobalTranslation('Plaatsnaam of postcode')"
                    :show-label="true"
                    :show-distance="true"
                    utilities="!max-w-full"
                    icon="far fa-location-dot"
                    type="locationQuery"
                    :loading="loading"
                    @setQuery="updateLocation($event)"
                />
                <ButtonPrimary
                    type="button"
                    :content="getGlobalTranslation('Zoeken')"
                    @click="scrollToResults"
                    utilities="w-full sm:w-fit !justify-center"
                />
            </div>

            <!-- desktop / tablet -->
            <div class="hidden lg:flex flex-wrap">
                <div class="flex mt-2 gap-2">
                    <i class="far fa-filter text-lg pt-1"></i>
                    <TextBody
                        utilities="font-bold text-lg"
                    >
                        {{ getGlobalTranslation('Filters:') }}
                    </TextBody>
                </div> 
                <FilterMultiSelect 
                    v-if="sectors && createFilter('sectors') && showSector"
                    :label="getGlobalTranslation('Sectoren')"
                    type="sectors"
                    :facet="createFilter('sectors')"
                    :loading="loading"
                    :selected="filters.sectors.values"
                    dropdown-position="left"
                    utilities="mr-4 mb-4"
                    @setFacet="updateFacet($event)"
                    :show-count="false"
                />
                <FilterMultiSelect 
                    v-if="workRegimes && createFilter('workRegimes')"
                    :label="getGlobalTranslation('Werkregimes')"
                    type="workRegimes"
                    :facet="createFilter('workRegimes')"
                    :loading="loading"
                    :selected="filters.workRegimes.values"
                    dropdown-position="left"
                    utilities="mr-4 mb-4"
                    @setFacet="updateFacet($event)"
                />
                <FilterMultiSelect 
                    v-if="contractTypes && createFilter('contractTypes')"
                    :label="getGlobalTranslation('Type contract')"
                    type="contractTypes"
                    :facet="createFilter('contractTypes')"
                    :loading="loading"
                    :selected="filters.contractTypes.values"
                    dropdown-position="left"
                    utilities="mr-4 mb-4"
                    @setFacet="updateFacet($event)"
                />
                <ButtonSecondary
                    type="button"
                    :content="getGlobalTranslation('Verwijder filters')"
                    @click="clearFilters"
                    utilities="mb-4 ml-auto"
                    icon="far fa-trash"
                    icon-position="after"
                />
            </div>


            <!-- mobile -->
            <div class="lg:hidden mr-2 border-t border-purple-600 pt-8">
                <ButtonSecondary
                    :aria-controls="mobileExpandId"
                    :aria-expanded="mobileExpanded"
                    :aria-label="`${mobileExpanded ? getGlobalTranslation('Inklappen van') : getGlobalTranslation('Uitklappen van')} ${getGlobalTranslation('Filter').toLowerCase()} ${getGlobalTranslation('Mobiele filters').toLowerCase()}`"
                    :badge="filtersSelected"
                    :content="getGlobalTranslation('Filter')"
                    @click="toggleMobile"
                    icon-position="before"
                    icon="far fa-filter"
                    type="button"
                    utilities="w-full sm:w-fit !justify-center"
                />
            </div>

            <div v-show="mobileExpanded" class="fixed left-0 top-0 inset-0 bg-black/20" />
            
            <div 
                class="bg-beige-600 fixed left-0 bottom-0 rounded-t-3xl p-4 w-full z-50"
                :class="mobileExpanded ? 'block' : 'hidden'"
                :id="mobileExpandId"
            >
                <header class="flex items-center justify-between p-4">
                    <TextH3>
                        Filter
                    </TextH3>
                    <ButtonPrimary
                        :aria-label="getGlobalTranslation('Sluit')"
                        icon="fa-solid fa-times"
                        size="small"
                        @click="toggleMobile"
                    />
                </header>

                <div class="px-4 max-h-[75vh] overflow-scroll scrollbar-hide divide-y space-y-2 pb-2">
                    <FilterMultiSelectMobile 
                        v-if="sectors && createFilter('sectors') && showSector"
                        :label="getGlobalTranslation('Sectoren')"
                        type="sectors"
                        :facet="createFilter('sectors')"
                        :loading="loading"
                        :selected="filters.sectors.values"
                        dropdown-position="left"
                        utilities="mr-4"
                        @setFacet="updateFacet($event)"
                        :show-count="false"
                    />
                    <FilterMultiSelectMobile 
                        v-if="workRegimes && createFilter('workRegimes')"
                        :label="getGlobalTranslation('Werkregimes')"
                        type="workRegimes"
                        :facet="createFilter('workRegimes')"
                        :loading="loading"
                        :selected="filters.workRegimes.values"
                        dropdown-position="left"
                        utilities="mr-4"
                        @setFacet="updateFacet($event)"
                    />
                    <FilterMultiSelectMobile 
                        v-if="contractTypes && createFilter('contractTypes')"
                        :label="getGlobalTranslation('Type contract')"
                        type="contractTypes"
                        :facet="createFilter('contractTypes')"
                        :loading="loading"
                        :selected="filters.contractTypes.values"
                        dropdown-position="left"
                        utilities="mr-4"
                        @setFacet="updateFacet($event)"
                    />
                </div>
                <div class="flex flex-col sm:flex-row gap-2">
                    <ButtonPrimary
                        type="button"
                        :content="getGlobalTranslation('Bevestig')"
                        @click="toggleMobile"
                        utilities="w-full !justify-center md:w-fit"
                    />
                    <ButtonSecondary
                            type="button"
                            :content="getGlobalTranslation('Verwijder filters')"
                            @click="clearFilters"
                            utilities="w-full !justify-center h-fit md:w-fit"
                            icon="far fa-trash"
                            icon-position="after"
                        />
                </div>
            </div>
        </div>
    </div>
</template>