<script setup lang="ts">
import { ref, computed } from 'vue'

import { Facet, FacetCount } from '~/js/interfaces/facets'

import { getGlobalTranslation } from '~/js/composables/translations'

import SvgLoader from '~/vue/_atoms/svgs/SvgLoader.vue'
import BadgeCounter from '~/vue/_atoms/badges/BadgeCounter.vue'

interface Props {
    disabled?: boolean,
    dropdownPosition?: string,
    facet: Facet,
    label: string,
    loading: boolean,
    selected?: Array<string> | null,
    showCount?: boolean,
    type: string,
    utilities?: string,
}

const props = withDefaults(defineProps<Props>(), { utilities: '', selected: null, disabled: false, dropdownPosition: 'left', showCount: false, })
const expanded = ref(false)
const emit = defineEmits(['setFacet'])
const uniqueKey = ref(Date.now())

const setFacet = (facet: string) => {
    emit('setFacet', {
        field: props.type, 
        value: facet
    })
}

const onClose = () => {
    expanded.value = false
}

const toggle = () => {
    expanded.value = !expanded.value
}

const ordered = computed(() => {
    return props.facet.counts
    // return [...props.facet.counts].sort((a: FacetCount, b: FacetCount) => (a.value < b.value) ? -1 : ((b.value < a.value) ? 1 : 0))
})

const uniqueId = computed(() => {
    return `${props.label.toLowerCase().replace(' ','-')}-${uniqueKey.value}`
})
</script>
<template>
    <fieldset
        v-click-away="onClose"
        :class="[
            'relative',
            disabled ? 'opacity-20' : '',
            utilities
        ]"
    >
        <legend class="sr-only">
            {{ label }}
        </legend>

        <button
            :class="[
                'py-2.5 inline-flex items-center print:hidden',
                'bg-beige-600',
                'font-bold font-primary',
                'focus:ring-4 focus:ring-celestial-500 focus:outline-none',
                'w-full'
            ]"
            type="button"
            :aria-controls="uniqueId"
            :aria-expanded="expanded"
            :aria-label="`${expanded ? getGlobalTranslation('Inklappen van') : getGlobalTranslation('Uitklappen van')} ${label.toLowerCase()} ${getGlobalTranslation('Filter').toLowerCase()}`"
            @click="toggle"
        >
            <span class="mr-auto whitespace-nowrap flex items-center pr-2 text-purple-600">
                <span class="pt-1">{{ label }}</span>
                
                <BadgeCounter 
                    v-if="selected && selected.length > 0"
                    :label="selected.length" 
                />
            </span>

            <span
                :class="[
                    'inline-flex items-center justify-end shrink-0 pr-3',
                ]"
            >
                <span
                    :class="[
                        !loading ? 'opacity-100' : 'opacity-0',
                        expanded ? 'rotate-180' : 'rotate-0',
                        'transition-transform duration-200 ease-in-out transform-gpu',
                        'text-purple-600',
                    ]"
                >
                    <i class="fas fa-chevron-down" />
                </span>
                <span
                    :class="[
                        loading ? 'opacity-100' : 'opacity-0',
                        'absolute right-0 top-1/2 -translate-y-1/2 text-purple-600',
                    ]"
                >
                    <SvgLoader />
                </span>
            </span>
        </button>

        <div 
            :class="expanded ? 'block' : 'hidden'"
            :id="uniqueId"
        >
            <template v-for="option in ordered">
                <button
                    :key="option.highlight"
                    v-if="option.count > 0"
                    type="button"
                    :class="[
                        'inline-flex items-center w-full',
                        'text-gray-blue-900',
                        'py-2',
                        'focus:ring-4 focus:ring-celestial-500 focus:outline-none',
                        'text-sm md:text-base text-left font-medium font-primary',
                        loading || (option.disabled ?? false) ? 'opacity-40 pointer-events-none' : '',
                    ]"
                    :data-tag-category="`filter-${type}`"
                    data-tag-action="filter"
                    :data-tag-label="option.highlighted"
                    @click="setFacet(option.highlighted)"
                >
                    <span :class="[ selected && selected.find(item => item == option.highlighted) > 0 ? 'inline-flex text-purple-600' : 'hidden' , 'text-base mr-2' ]">
                        <i class="fa-solid fa-square-check" />
                    </span>

                    <span :class="[ selected && selected.find(item => item == option.highlighted) > 0 ? 'hidden' : 'inline-flex' , 'text-base mr-2' ]">
                        <i class="fa-regular fa-square" />
                    </span>
                    <span class="md:whitespace-nowrap space-x-1">
                        <span>{{ option.value }}</span>
                        <span v-if="showCount">({{ option.count }})</span>
                    </span>
                </button>
            </template>
        </div>
    </fieldset>
</template>
