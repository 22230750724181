<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

import { getClasses } from '~/js/composables/classes'
import { getGlobalTranslation } from '~/js/composables/translations'

import SvgLoader from '~/vue/_atoms/svgs/SvgLoader.vue'
import ButtonPrimary from '~/vue/_atoms/buttons/ButtonPrimary.vue'
import ButtonTertiary from '~/vue/_atoms/buttons/ButtonTertiary.vue'
import ButtonLink from '~/vue/_atoms/buttons/ButtonLink.vue'
import TextBody from '~/vue/_atoms/texts/TextBody.vue'
import TextH2 from '~/vue/_atoms/texts/TextH2.vue'

interface Props {
    title: String,
    description: String,
    show?: boolean
}

// props
withDefaults(defineProps<Props>(), {
    show: false
})
const emit = defineEmits(['showLoginDrawer', 'loggedIn'])

// variables
const classes = ref({
    wrapper: {
        _default: 'fixed left-0 top-0 z-30 !mt-0',
        animation: 'transition-all duration-500',
        color: 'bg-purple-600/80',
        flex: 'flex flex-col items-end justify-end sm:justify-center',
        size: 'w-full h-full',
    },
    container: {
        _default: '',
        animation: 'transition-all duration-500',
        color: 'bg-beige-600 w-full sm:max-w-[80%]',
        flex: 'flex items-center sm:grow',
        rounded: 'rounded-t-3xl sm:rounded-none',
        spacing: 'pt-nav-mobile '
    },
    content: {
        _default: 'container mx-auto ',
        scroll: 'overflow-scroll scrollbar-hide',
        size: 'max-w-2xl max-h-[calc(100vh-66px)]',
        spacing: 'py-8'
    },
})
const messages = ref({
    errors: null,
    message: null,
})
const user = ref({
    loginName: null,
    password: null,
})
const loading = ref(false)
const random = ref((Math.random() + 1).toString(36).substring(7))

const getPasswordForgotLink = computed(() => {
    return window.urls.passwordForgotton
})

const getRegisterLink = computed(() => {
    return window.urls.register
})

// methods
const handleLogin = async () => {
    loading.value = true
    messages.value.errors = null
    messages.value.message = null

    // Prepare the form data
    const formData = new FormData()
    formData.append(window.csrf.name, window.csrf.value)
    formData.append('action', 'users/login')
    formData.append('loginName', user.value.loginName)
    formData.append('password', user.value.password)

    try {
        // Send AJAX request to Craft's login action
        const response = await fetch(`/`, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
            },
        })

        if (!response.ok) {
            loading.value = false

            if (response.status === 400) {
                // Parse the error message for 400 responses
                const errorData = await response.json()
                messages.value.errors = errorData.message || 'Bad Request: Invalid input.'
            } else {
                // Handle other HTTP errors
                messages.value.errors = `Unexpected error: ${response.status} ${response.statusText}`
            }

            return
        }

        // Parse and handle the JSON response for successful requests
        const data = await response.json()

        if (data?.user?.id ?? null) {
            window.csrf.value = data.csrfTokenValue
            window.userId = data.user.id
            window.hasCurrentUser = true

            // reload the navigation
            const $user = document.getElementById('user-dropdown')
            if ($user) {
                fetch('/lods/lod--usermenu')
                    .then(response => response.text())
                    .then(html => $user.innerHTML = html)
            }

            const $userMobile = document.getElementById('user-dropdown-mobile')
            if ($user) {
                fetch('/lods/lod--usermenu-mobile')
                    .then(response => response.text())
                    .then(html => $userMobile.innerHTML = html)
            }
        }

        loading.value = false
        
        emit('loggedIn')
    } catch (error) {
        // Handle network or other unexpected errors
        messages.value.errors = 'An error occurred. Please try again.'
        console.error('Login Error:', error)
    }
}

const handleCloseDrawer = () => {
    emit('showLoginDrawer', false)
}

// lifecycle
onMounted(() => {
    document.addEventListener('keyup', evt => {
        if (evt.key == 'Escape') {
            handleCloseDrawer()
        }
    })
})
</script>

<template>
    <div 
        :class="[
            getClasses(classes.wrapper),
            !show ? 'pointer-events-none' : '',
            !show ? '!bg-transparent' : ''
        ]"
    >
        <div 
            :class="[
                getClasses(classes.container),
                !show ? 'translate-y-full sm:translate-y-0 sm:translate-x-full opacity-0' : ''
            ]"
        >
            <div :class="getClasses(classes.content)">

                <div class="hidden sm:flex justify-end mb-4">
                    <ButtonTertiary
                        type="button"
                        icon="fa-solid fa-times"
                        iconPosition="before"
                        :content="getGlobalTranslation('Sluiten')"
                        :ga="{
                            category: 'close-drawer-login',
                            action: 'click',
                            label: getGlobalTranslation('Sluiten')
                        }"
                        @click="handleCloseDrawer"
                    />
                </div>

                <div class="flex items-center justify-between gap-8">
                    <TextH2 utilities="sm:mb-4">
                        {{ title }}
                    </TextH2>
                    <ButtonPrimary
                        :aria-label="getGlobalTranslation('Sluiten')"
                        icon="fa-solid fa-times"
                        size="small"
                        utilities="sm:hidden"
                        @click="handleCloseDrawer"
                    />
                </div>

                <TextBody utilities="block mt-4">
                    {{ description }}
                </TextBody>

                <form 
                    accept-charset="UTF-8"
                    class="font-primary"
                    @submit.prevent="handleLogin"
                >
                    <div 
                        v-if="messages.errors"
                        class="fui-alert fui-alert-error fui-alert-top-form bg-[#fdf2f2] p-4 rounded-md mt-4" 
                        role="alert"
                    >
                        <p class="inline-block pt-1">{{ messages.errors }}</p>
                    </div>

                    <div class="fui-row fui-page-row">
                        <div class="fui-field fui-type-single-line-text fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-field-required fui-page-field">
                            <div class="fui-field-container">
                                <label class="fui-label" :for="`fui-login-${random}-loginName`">E-mailadres&nbsp;<span class="fui-required" aria-hidden="true">*</span></label>
                                <div class="fui-input-wrapper">
                                    <input type="text" :id="`fui-login-${random}-loginName`" class="fui-input" name="loginName" v-model="user.loginName">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="fui-row fui-page-row">
                        <div class="fui-field fui-type-password fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-field-required fui-page-field">
                            <div class="fui-field-container">
                                <label class="fui-label" :for="`fui-login-${random}-password`">Wachtwoord&nbsp;<span class="fui-required" aria-hidden="true">*</span></label>
                                <div class="fui-input-wrapper">
                                    <input type="password" :id="`fui-login-${random}-password`" class="fui-input" name="password" autocomplete="off" v-model="user.password">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="fui-row fui-page-row items-center">
                        <span class="grow">
                            <span class="relative block w-fit">
                                <span
                                    :class="[
                                        loading ? 'opacity-100' : 'opacity-0',
                                        'pointer-events-none',
                                        'absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 text-purple-600',
                                    ]"
                                >
                                    <SvgLoader />
                                </span>
                                <ButtonPrimary
                                    type="submit"
                                    :utilities="`whitespace-nowrap ${loading ? 'pointer-events-none' : ''}`"
                                    :content="getGlobalTranslation('Login')"
                                    :disabled="loading"
                                    :ga="{
                                        category: 'account',
                                        action: 'click',
                                        label: getGlobalTranslation('Login')
                                    }"
                                />  
                            </span>
                        </span>

                        <span>
                            <ButtonLink
                                type="a"
                                :href="getPasswordForgotLink"
                            >
                                {{ getGlobalTranslation('Ben je je wachtwoord vergeten?') }}
                            </ButtonLink>
                        </span>
                    </div>

                    <div class="fui-row fui-page-row pt-4 sm:pt-8">
                        <ButtonLink
                            type="a"
                            :href="getRegisterLink"
                            :ga="{
                                category: 'account',
                                action: 'click',
                                label: getGlobalTranslation('Registreer')
                            }"
                        >
                            {{ getGlobalTranslation('Heb je nog geen account? Registreer je hier') }}
                        </ButtonLink>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>